// Copyright 2025. WebPros International GmbH. All rights reserved.

import React from 'react';
import { Translate } from '@plesk/ui-library';
import moment from 'moment';
import { IConfirmOn } from 'common/components/SwitchWithConfirmation';
import { ACTIONS } from 'common/modules/backup/constants/tests';
import { CardWithSwitch } from 'common/components/CardWithSwitch/CardWithSwitch';
import { connect } from 'react-redux';
import { ICommonState } from 'common/store';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { getProcessedErrors } from 'common/modules/app/formErrors/selectors';

export interface IDisasterRecoveryCardProps {
    isDisasterRecoveryEnabled: boolean;
    isUpdating: boolean;
    shouldConfirmOnDisable?: boolean;
    disabled?: boolean;
    switchTooltip?: React.ReactNode;
    setDisasterRecoveryEnabled: (enabled: boolean) => void;
    lastBackupDate: string | null;
}

export type DisasterRecoveryCardProps =
    IDisasterRecoveryCardProps &
    ReturnType<typeof mapStateToProps>;

export const DisasterRecoveryCard: React.FC<DisasterRecoveryCardProps> = ({
    setDisasterRecoveryEnabled,
    isDisasterRecoveryEnabled,
    isUpdating,
    disabled,
    switchTooltip,
    lastBackupDate,
    shouldConfirmOnDisable,
}) => {

    const disableConfirmText = (<Translate content='disasterRecoveryCard.disableConfirm.text' />);

    const confirmOnDisable: IConfirmOn = {
        enabled: !!shouldConfirmOnDisable,
        translations: {
            title: (
                <Translate content='disasterRecoveryCard.disableConfirm.title' />
            ),
            text: disableConfirmText,
            button: (
                <Translate content='disasterRecoveryCard.disableConfirm.button' />
            ),
        },
    };

    const handleDisasterRecoveryEnabledChange = (isEnabled: boolean) => {
        setDisasterRecoveryEnabled(isEnabled);
    };

    return (
        <CardWithSwitch
            title={<Translate content='disasterRecoveryCard.title' />}
            isEnabled={isDisasterRecoveryEnabled}
            isLoading={isUpdating}
            isDisabled={disabled}
            handleChange={handleDisasterRecoveryEnabledChange}
            confirmOnDisable={confirmOnDisable}
            tooltip={switchTooltip}
            switch-data-cy={ACTIONS.DISASTER_RECOVERY_ENABLE_SWITCH}
        >
            <>
                {lastBackupDate ? (
                    <div>
                        <Translate
                            content="disasterRecoveryCard.content.enabled"
                            params={{
                                last_backup_date: moment(lastBackupDate).format('DD-MM-YYYY HH:mm'),
                            }}
                        />
                    </div>
                ) : (
                    <div>
                        <Translate content="disasterRecoveryCard.content.noBackups"/>
                    </div>
                )}
            </>
        </CardWithSwitch>
    );
};

const mapStateToProps = (state: ICommonState) => ({
    isSaving: state.app.loadingFlags.has(LOADING_FLAGS.SERVER_DISASTER_RECOVERY_SETTINGS_UPDATE),
    formErrors: getProcessedErrors(state),
});

export default connect(mapStateToProps)(DisasterRecoveryCard);
