// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    Header,
    Title,
} from 'admin/common/components/PageHeader/Styles';

export interface IPageHeaderProps {
    title: React.ReactNode;
}

export const PageHeaderDR: React.FC<IPageHeaderProps> = ({ title }) => (
    <Header>
        <Title>{title}</Title>
    </Header>
);