// Copyright 2025. WebPros International GmbH. All rights reserved.

import { connect } from 'react-redux';
import { ResizeTab } from 'common/components/ServerTabs/ResizeTab/ResizeTab';
import { RootState } from 'admin/core/store';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import {
    getPlans,
    loadPlansOnScroll,
} from 'common/modules/plan/actions';
import { resizeComputeResourceVm } from 'common/modules/computeResourceVm/actions';
import { hasPermission } from 'common/modules/permission/selectors';
import { PERMISSION_LIST } from 'common/modules/permission/constants';
import { FEATURES } from 'common/modules/app/features/constants';

const mapStateToProps = (state: RootState) => ({
    hasNextPage: !!state.plan.list.links.next,
    plans: state.plan.list.data.filter(plan => {
        const { plan: serverPlan, specifications } = state.computeResourceVm.item;

        return plan.id !== serverPlan.id && plan.params.disk >= specifications.disk;
    }),
    project: state.project.item,
    server: state.computeResourceVm.item,
    isLoading: state.app.loadingFlags.has(LOADING_FLAGS.PLAN_LIST),
    isResizing: state.app.loadingFlags.has(LOADING_FLAGS.COMPUTE_RESOURCE_VM_RESIZE),
    hidePlanName: false,
    withPriceInfo: false,
    canManageBackups: hasPermission(state, PERMISSION_LIST.MANAGE_BACKUPS),
    canCustomizePlan: true,
    withDisasterRecovery: state.app.features.has(FEATURES.DISASTER_RECOVERY), // todo: SVM2-6353 delete this when disaster recovery is ready
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getPlans: bindActionCreators(getPlans, dispatch),
    resize: bindActionCreators(resizeComputeResourceVm, dispatch),
    loadOnScroll: bindActionCreators(loadPlansOnScroll, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResizeTab);
