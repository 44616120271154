// Copyright 2025. WebPros International GmbH. All rights reserved.

import { clearFormErrors } from 'common/modules/app/formErrors/actions';
import { IAppState } from 'admin/core/store';
import { INTENT_TYPE } from 'common/constants';
import { bakeForegroundToast } from 'common/modules/app/toaster/actions';
import {
    setIsLoading,
    unsetIsLoading,
} from 'common/modules/app/loadingFlags/actions';
import { createCustomAction } from 'typesafe-actions';
import { Dispatch } from 'redux';
import { HTTP_CODES } from 'common/api/constants';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import * as types from 'admin/computeResource/constants/types';
import {
    get,
    loadOnScroll,
    remove,
} from 'common/actions/actionsWrapper';
import { IPaginateApiResponse } from 'common/api/resources/Response';
import { CancelTokenSource } from 'axios';
import {
    computeResources,
    IComputeResourceCreateRequest,
    IComputeResourceDeleteRequest,
    IComputeResourceInstallStepResponse,
    IComputeResourcePatchRequest,
    IComputeResourceResponse,
    IComputeResourceSettingsResponse,
    ILogicalVolumeResponse,
    IMetricResponse,
    INetworkResponse,
    IPhysicalVolumeResponse,
    ISetUpNetworkRequest,
    IUsageResponse,
} from 'common/api/resources/ComputeResource';
import {
    IStorageCreateRequest,
    IStoragePatchRequest,
    IStorageResponse,
    IStorageUpdateRequest,
    storages,
} from 'common/api/resources/Storage';
import { IIpBlockResponse } from 'common/api/resources/IpBlock';
import { remove as keypairCacheRemove } from 'admin/computeResource/services/cache';
import { IPaginatedWithSearch } from 'common/api/resources/Request/request';

export const setList = createCustomAction(
    types.SET_COMPUTE_RESOURCE_LIST,
    (data: IPaginateApiResponse<IComputeResourceResponse[]>) => ({ payload: data })
);
export const appendComputeResources = createCustomAction(
    types.APPEND_COMPUTE_RESOURCES,
    (data: IPaginateApiResponse<IComputeResourceResponse[]>) => ({ payload: data })
);
export const setItem = createCustomAction(
    types.SET_COMPUTE_RESOURCE_ITEM,
    (data: IComputeResourceResponse) => ({ payload: data })
);
export const unsetItem = createCustomAction(types.UNSET_COMPUTE_RESOURCE_ITEM);
export const updateItem = createCustomAction(
    types.UPDATE_COMPUTE_RESOURCE_ITEM,
    (data: IComputeResourceResponse) => ({ payload: data })
);
export const addItem = createCustomAction(
    types.ADD_COMPUTE_RESOURCE_ITEM,
    (data: IComputeResourceResponse) => ({ payload: data })
);
export const deleteItem = createCustomAction(
    types.DELETE_COMPUTE_RESOURCE,
    (id: number) => ({ payload: id })
);
export const deleteStorage = createCustomAction(
    types.DELETE_COMPUTE_RESOURCE_STORAGE,
    (id: number) => ({ payload: id })
);
export const setNetworks = createCustomAction(
    types.SET_COMPUTE_RESOURCE_NETWORKS,
    (data: INetworkResponse[]) => ({ payload: data })
);
export const setIpBlocks = createCustomAction(
    types.SET_COMPUTE_RESOURCE_IP_BLOCKS,
    (data: IIpBlockResponse[]) => ({ payload: data })
);
export const setStorages = createCustomAction(
    types.SET_COMPUTE_RESOURCE_STORAGES,
    (data: IStorageResponse[]) => ({ payload: data })
);
export const setMetrics = createCustomAction(
    types.SET_COMPUTE_RESOURCE_METRICS,
    (data: IMetricResponse[]) => ({ payload: data })
);
export const setPhysicalVolumes = createCustomAction(
    types.SET_COMPUTE_RESOURCE_PHYSICAL_VOLUMES,
    (data: IPhysicalVolumeResponse[]) => ({ payload: data })
);
export const setThinPools = createCustomAction(
    types.SET_COMPUTE_RESOURCE_THIN_POOLS,
    (data: ILogicalVolumeResponse[]) => ({ payload: data })
);
export const setUsage = createCustomAction(
    types.SET_COMPUTE_RESOURCE_USAGE,
    (data: IUsageResponse) => ({ payload: data })
);
export const setInstallSteps = createCustomAction(
    types.SET_COMPUTE_RESOURCE_INSTALL_STEPS,
    (id: number, data: IComputeResourceInstallStepResponse[]) => ({ payload: { id, data } })
);
export const updateInstallStep = createCustomAction(
    types.UPDATE_COMPUTE_RESOURCE_INSTALL_STEP,
    (data: IComputeResourceInstallStepResponse) => ({ payload: data })
);
export const addStorage = createCustomAction(
    types.ADD_COMPUTE_RESOURCE_STORAGE,
    (data: IStorageResponse) => ({ payload: data })
);
export const patchStorage = createCustomAction(
    types.PATCH_COMPUTE_RESOURCE_STORAGE,
    (data: IStorageResponse) => ({ payload: data })
);
export const setSettings = createCustomAction(
    types.SET_COMPUTE_RESOURCE_SETTINGS,
    (id: number, data: IComputeResourceSettingsResponse) => ({ payload: { id, data } })
);
export const setItemId = createCustomAction(
    types.SET_COMPUTE_RESOURCE_ITEM_ID,
    (id: number) => ({ payload: id })
);
export const setCRItemIsConfiguringNetwork = createCustomAction(
    types.SET_COMPUTE_RESOURCE_ITEM_IS_CONFIGURING_NETWORK,
    (index: number) => ({ payload: index })
);
export const unsetCRItemIsConfiguringNetwork = createCustomAction(
    types.UNSET_COMPUTE_RESOURCE_ITEM_IS_CONFIGURING_NETWORK,
    (index: number) => ({ payload: index })
);
export const setCRItemIsDeleting = createCustomAction(
    types.SET_COMPUTE_RESOURCE_ITEM_IS_DELETING,
    (id: number, isDeleting: boolean) => ({ payload: { id, isDeleting } })
);
export const setCRStorageItemIsDeleting = createCustomAction(
    types.SET_COMPUTE_RESOURCE_STORAGE_ITEM_IS_DELETING,
    (id: number, isDeleting: boolean) => ({ payload: { id, isDeleting } })
);

export const updateSettings = (id: number, data: IComputeResourceSettingsResponse) => async(dispatch: Dispatch) =>  {
    dispatch(setIsLoading(LOADING_FLAGS.SAVE_COMPUTE_RESOURCE_SETTINGS));

    try {
        const result = await computeResources.settings.update(id, data);
        dispatch(setSettings(id, result.data.data));
        dispatch(clearFormErrors());
        bakeForegroundToast(INTENT_TYPE.SUCCESS, 'computeResource.settings.settingsSaveSuccess')(dispatch);

        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.SAVE_COMPUTE_RESOURCE_SETTINGS));
    }
};

export const getSettings = (id: number) => async(dispatch: Dispatch) =>  {
    dispatch(setIsLoading(LOADING_FLAGS.COMPUTE_RESOURCE_SETTINGS));

    try {
        const result = await computeResources.settings.item(id);
        dispatch(setSettings(id, result.data.data));

        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.COMPUTE_RESOURCE_SETTINGS));
    }
};

export const getStorages = (id: number) => async(dispatch: Dispatch) => await get(id, {
    dispatch,
    loadingFlag: LOADING_FLAGS.COMPUTE_RESOURCE_STORAGE_LIST,
    action: setStorages,
    apiCall: computeResources.storages.list,
});

export const createStorage = (id: number, data: IStorageCreateRequest) => async(dispatch: Dispatch) =>  {
    dispatch(setIsLoading(LOADING_FLAGS.CREATE_COMPUTE_RESOURCE_STORAGE));
    try {
        const result = await computeResources.storages.create(id, data);

        bakeForegroundToast(INTENT_TYPE.SUCCESS, 'computeResource.storages.itemCreateSuccess')(dispatch);
        dispatch(clearFormErrors());
        dispatch(addStorage(result.data.data));

        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.CREATE_COMPUTE_RESOURCE_STORAGE));
    }
};

export const updateStorage = (id: number, storageId: number, data: IStorageUpdateRequest) => async(dispatch: Dispatch) =>  {
    dispatch(setIsLoading(LOADING_FLAGS.SAVE_COMPUTE_RESOURCE_STORAGE));
    try {
        const result = await computeResources.storages.update(id, storageId, data);

        bakeForegroundToast(INTENT_TYPE.SUCCESS, 'computeResource.storages.itemUpdateSuccess')(dispatch);
        dispatch(clearFormErrors());
        dispatch(patchStorage(result.data.data));

        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.SAVE_COMPUTE_RESOURCE_STORAGE));
    }
};

export const getComputeResourceIpBlocks = (id: number) => async(dispatch: Dispatch) => await get(id, {
    dispatch,
    loadingFlag: LOADING_FLAGS.COMPUTE_RESOURCE_IP_BLOCK_LIST,
    action: setIpBlocks,
    apiCall: computeResources.ipBlocks.list,
});

export const setUpNetwork = (computeResourceId: number, data: ISetUpNetworkRequest) => async(dispatch: Dispatch) => {
    dispatch(setCRItemIsConfiguringNetwork(computeResourceId));

    try {
        const result = await computeResources.api(computeResourceId).setupNetwork(data);

        dispatch(updateItem(result.data.data));
        dispatch(setItem(result.data.data));
        bakeForegroundToast(INTENT_TYPE.SUCCESS, 'computeResource.actionDialog.network.networkConfiguringStarted')(dispatch);
    } finally {
        dispatch(unsetCRItemIsConfiguringNetwork(computeResourceId));
    }
};

export const getComputeResourceMetrics = (id: number) => async(dispatch: Dispatch) => {
    const result = await computeResources.api(id).metrics();

    dispatch(setMetrics(result.data.data));
};

export const getComputeResourcePhysicalVolumes = (id: number) => async(dispatch: Dispatch) => {
    const result = await computeResources.api(id).physicalVolumes();

    dispatch(setPhysicalVolumes(result.data.data));
};

export const getComputeResourceThinPools = (id: number) => async(dispatch: Dispatch) => {
    const result = await computeResources.api(id).thinPools();

    dispatch(setThinPools(result.data.data));
};

export const getComputeResourceUsage = (id: number) => async(dispatch: Dispatch) => {
    const result = await computeResources.usage(id);

    dispatch(setUsage(result.data.data));
};

export const getComputeResourceNetworks = (id: number) => async(dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.COMPUTE_RESOURCE_NETWORKS));

    try {
        const result = await computeResources.api(id).networks();

        dispatch(setNetworks(result.data.data));
    } catch (e) {
        dispatch(setNetworks([]));

        throw e;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.COMPUTE_RESOURCE_NETWORKS));
    }
};

export const getComputeResources = (
    params?: IPaginatedWithSearch<unknown, string, string> | boolean
) => async(dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.COMPUTE_RESOURCE_LIST));

    try {
        const filters: Record<string, string> = {};

        if (typeof params === 'boolean') {
            filters.offline = String(params);
        }
        else if (params && typeof params === 'object') {
            // @ts-ignore
            if ('search' in params && params.search) {
                // @ts-ignore
                filters.search = params.search;
            }
            // @ts-ignore
            if ('offline' in params && params.offline !== undefined) {
                // @ts-ignore
                filters.offline = String(params.offline);
            }
        }

        const data = {
            filters,
        };

        const result = await computeResources.list(data);
        if (result.status === HTTP_CODES.OK) {
            dispatch(setList(result.data));
        }

        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.COMPUTE_RESOURCE_LIST));
    }
};

export const loadComputeResourcesOnScroll = () => async(dispatch: Dispatch, getState: () => IAppState) => {
    const state = getState();
    const nextPage = state.computeResource.list.links.next;
    const isLoading = state.app.loadingFlags.has(LOADING_FLAGS.COMPUTE_RESOURCE_LIST);

    return await loadOnScroll({
        nextPage,
        isLoading,
        dispatch,
        action: appendComputeResources,
        loadingFlag: LOADING_FLAGS.COMPUTE_RESOURCE_LIST,
    });
};

export const createComputeResource = (data: IComputeResourceCreateRequest) => async(dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.CREATE_COMPUTE_RESOURCE));
    try {
        const result = await computeResources.create(data);

        dispatch(clearFormErrors());
        dispatch(addItem(result.data.data));
        dispatch(setItem(result.data.data));
        bakeForegroundToast(INTENT_TYPE.SUCCESS, 'computeResource.itemCreateSuccess')(dispatch);

        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.CREATE_COMPUTE_RESOURCE));
    }
};

export const updateComputeResource = (id: number, data: IComputeResourcePatchRequest) => async(dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.CREATE_COMPUTE_RESOURCE));

    try {
        const result = await computeResources.patch(id, data);

        dispatch(clearFormErrors());
        dispatch(updateItem(result.data.data));
        dispatch(setItem(result.data.data));
        bakeForegroundToast(INTENT_TYPE.SUCCESS, 'computeResource.itemUpdateSuccess')(dispatch);

        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.CREATE_COMPUTE_RESOURCE));
    }
};

export const updateIsLocked = (id: number, isLocked: boolean) => async(dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.UPDATE_COMPUTE_RESOURCE_IS_LOCKED));

    try {
        const result = await computeResources.patch(id, { is_locked: isLocked });

        dispatch(updateItem(result.data.data));

        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.UPDATE_COMPUTE_RESOURCE_IS_LOCKED));
    }
};

export const updateIsInMaintenance = (id: number, isInMaintenance: boolean) => async(dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.UPDATE_COMPUTE_RESOURCE_IS_IN_MAINTENANCE));

    try {
        const result = await computeResources.patch(id, { is_in_maintenance: isInMaintenance });

        dispatch(updateItem(result.data.data));

        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.UPDATE_COMPUTE_RESOURCE_IS_IN_MAINTENANCE));
    }
};

export const retryInstallationComputeResource = (id: number, data: IComputeResourceCreateRequest) => async(dispatch: Dispatch) =>  {
    dispatch(setIsLoading(LOADING_FLAGS.CREATE_COMPUTE_RESOURCE));

    try {
        const result = await computeResources.retryInstallation(id, data);

        dispatch(clearFormErrors());
        dispatch(updateItem(result.data.data));
        dispatch(setItem(result.data.data));
        bakeForegroundToast(INTENT_TYPE.SUCCESS, 'computeResource.itemUpdateSuccess')(dispatch);

        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.CREATE_COMPUTE_RESOURCE));
    }
};

export const getComputeResource = (id: number, cancelToken?: CancelTokenSource) => (dispatch: Dispatch) => get(id, {
    dispatch,
    loadingFlag: LOADING_FLAGS.COMPUTE_RESOURCE_ITEM,
    action: setItem,
    apiCall: computeResources.item,
}, cancelToken);

export const getInstallSteps = (id: number, cancelToken?: CancelTokenSource) => async(dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.INSTALL_STEPS_LIST));

    try {
        const result = await computeResources.installSteps.list(id, cancelToken);
        dispatch(setInstallSteps(id, result.data.data));

        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.INSTALL_STEPS_LIST));
    }
};

export const removeComputeResource = (id: number, data: IComputeResourceDeleteRequest) => async (dispatch: Dispatch) => {
    dispatch(setCRItemIsDeleting(id, true));

    try {
        const result = await computeResources.remove(id, data);

        if (result.status === HTTP_CODES.NO_CONTENT) {
            bakeForegroundToast(INTENT_TYPE.SUCCESS, 'computeResource.itemDeleteSuccess')(dispatch);
            dispatch(deleteItem(id));
            keypairCacheRemove(id);
        }

        return result;
    } finally {
        dispatch(setCRItemIsDeleting(id, false));
    }
};

export const patchComputeResourceStorage = (id: number, data: IStoragePatchRequest) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.PATCH_COMPUTE_RESOURCE_STORAGE));

    try {
        const result = await storages.patch(id, data);
        dispatch(patchStorage(result.data.data));

        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.PATCH_COMPUTE_RESOURCE_STORAGE));
    }
};

export const removeComputeResourceStorage = (id: number) => async (dispatch: Dispatch) => await remove(id, {
    dispatch,
    apiCall: storages.remove,
    setLoadingAction: setCRStorageItemIsDeleting,
    action: deleteStorage,
    loadingFlag: LOADING_FLAGS.REMOVE_COMPUTE_RESOURCE_STORAGE,
    translations: {
        success: 'computeResource.storage.deletedSuccessfully',
    },
});

export const mountComputeResourceStorage = (storageId: number, computeResourceId: number) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.CREATE_COMPUTE_RESOURCE_STORAGE));
    try {
        const result = await storages.mount(storageId, {
            compute_resource_id: computeResourceId,
        });

        bakeForegroundToast(INTENT_TYPE.SUCCESS, 'computeResource.storage.mountedSuccessfully')(dispatch);
        dispatch(clearFormErrors());
        dispatch(addStorage(result.data.data));

        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.CREATE_COMPUTE_RESOURCE_STORAGE));
    }
};

export const unmountComputeResourceStorage = (storageId: number, computeResourceId: number) => async (dispatch: Dispatch) => await remove(storageId, {
    dispatch,
    apiCall: () => storages.unmount(storageId, {
        compute_resource_id: computeResourceId,
    }),
    setLoadingAction: setCRStorageItemIsDeleting,
    action: deleteStorage,
    loadingFlag: LOADING_FLAGS.REMOVE_COMPUTE_RESOURCE_STORAGE,
    translations: {
        success: 'computeResource.storage.unmountedSuccessfully',
    },
});
