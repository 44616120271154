// Copyright 2025. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { RootState } from 'admin/core/store';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import * as computeResourceActions from 'admin/computeResource/actions';
// eslint-disable-next-line no-restricted-imports
import { PageHeaderDR } from '../components/PageHeaderDR';
import InfiniteScroll from 'common/components/InfinityScroll/InfinityScroll';
import { StyledTable } from 'common/components/styles/StyledTable';
import { Translate } from '@plesk/ui-library';
import { Loader } from 'common/components';
import { getSelectedComputeResource } from 'admin/computeResource/selectors';
import { hasPermission } from 'common/modules/permission/selectors';
import { PERMISSION_LIST } from 'common/modules/permission/constants';
// eslint-disable-next-line no-restricted-imports
import ComputeResourceDRTable from '../../disasterRecovery/components/ComputeResourceDRTable';

export type DisasterRecoveryProps =
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const DisasterRecovery: React.FC<DisasterRecoveryProps> = ({
    canGetComputeResources,
    loadingFlags: {
        isLoadingList,
    },
    list,
    computeResourceActions: {
        loadComputeResourcesOnScroll,
        getComputeResources,
    },
}) => {

    React.useEffect(() => {
        // @ts-ignore
        getComputeResources(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!canGetComputeResources) {
        return <Translate content="accessDenied" />;
    }

    return (
        <>
            <PageHeaderDR
                title={<Translate content="computeResource.disasterRecovery.title"/>}
            />
            <StyledTable>
                <InfiniteScroll
                    loadMore={loadComputeResourcesOnScroll}
                    hasMore={!!list.links.next}
                >
                    <Loader isLoading={isLoadingList}>
                        <ComputeResourceDRTable
                            items={list.data}
                        />
                    </Loader>
                </InfiniteScroll>
            </StyledTable>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    list: state.computeResource.list,
    selectedItem: getSelectedComputeResource(state),
    canGetComputeResources: hasPermission(state, PERMISSION_LIST.GET_COMPUTE_RESOURCES) || hasPermission(state, PERMISSION_LIST.MANAGE_COMPUTE_RESOURCES),
    loadingFlags: {
        isLoadingList: !state.computeResource.list.data.length && state.app.loadingFlags.has(LOADING_FLAGS.COMPUTE_RESOURCE_LIST),
    },
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    computeResourceActions: bindActionCreators(computeResourceActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DisasterRecovery);
