// Copyright 2025. WebPros International GmbH. All rights reserved.


import * as React from 'react';
import { IComputeResourceResponse } from 'common/api/resources/ComputeResource';
import {
    Action,
    List,
    Translate,
} from '@plesk/ui-library';
import {
    RouteComponentProps,
    withRouter,
} from 'react-router';
import { connect } from 'react-redux';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { EmptyView } from 'common/components/EmptyView/EmptyView';
import * as computeResourceActions from 'admin/computeResource/actions';
import { Link } from 'react-router-dom';
import { pathTo } from 'common/helpers/core';
import { getActionColumnProps } from 'common/helpers/list';
import CopyText from 'common/containers/CopyText/CopyText';

const columns = [{
    key: 'colName',
    cellProps: {
        className: 'cell-bold',
    },
    title: <Translate content="computeResource.list.name" />,
}, {
    width: '10%',
    key: 'colDrEnabledVms',
    title: <Translate content="computeResource.disasterRecovery.list.drEnabledVms" />,
    cellProps: {
        style: { textAlign: 'center' },
    },
}, {
    width: '10%',
    key: 'colDrDisabledVmsWithBackups',
    title: <Translate content="computeResource.disasterRecovery.list.drDisabledVmsWithBackups" />,
    cellProps: {
        style: { textAlign: 'center' },
    },
}, {
    width: '10%',
    key: 'colLastSeenOnline',
    title: <Translate content="computeResource.disasterRecovery.list.lastSeenOnline" />,
    cellProps: {
        style: { textAlign: 'center' },
    },
}, getActionColumnProps(),
];

export interface IComputeResourceTableProps extends RouteComponentProps {
    items: IComputeResourceResponse[];
}

export type ComputeResourceTableProps =
    IComputeResourceTableProps &
    ReturnType<typeof mapDispatchToProps>;

export const ComputeResourceTableDR: React.FC<ComputeResourceTableProps> = ({
    items,
}) => {
    const data = items
        .map((item) => ({
            colName: item.id && (
                <>
                    <div>
                        <Action
                            component={Link}
                            to={pathTo(`compute_resources/${item.id}`)} // todo: SVM2-6264 add path to specific CR recovery
                        >
                            {item.name}
                        </Action>
                    </div>
                    <CopyText>{item.host}</CopyText>
                </>
            ),
            colDrEnabledVms: item.disaster_recovery_enabled_servers_count,
            colDrDisabledVmsWithBackups: item.backup_enabled_servers_count,
            colLastSeenOnline: item.last_seen_online,
            key: item.id.toString(),
        }));

    return (
        <List
            emptyView={
                <EmptyView
                    title="computeResource.emptyView.title"
                    description="computeResource.emptyView.description"
                    buttonText="computeResource.emptyView.buttonText"
                />
            }
            columns={columns}
            data={data}
        />
    );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    computeResourceActions: bindActionCreators(computeResourceActions, dispatch),
});

export default withRouter(connect(null, mapDispatchToProps)(ComputeResourceTableDR));